export const maintenColumnData = [
  {
    fieldName: "itemId",
    display: true,
    fieldLabel: "工单号",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "taskSecondClassStr",
    display: true,
    fieldLabel: "工单类型",
    width: 110,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "isNeedVisitName",
    display: true,
    fieldLabel: "是否上门",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "corpName",
    display: true,
    fieldLabel: "客户名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "nowOperationOrgName",
    display: true,
    fieldLabel: "现运营组织",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "engineerName",
    display: true,
    fieldLabel: "工程师",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionTagName",
    display: true,
    fieldLabel: "质检标签",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "sceneName",
    display: true,
    fieldLabel: "场景名称",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "sourceOrganizationName",
    display: true,
    fieldLabel: "上线平台",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "materialName",
    display: true,
    fieldLabel: "物料名称",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "classifyName",
    display: true,
    fieldLabel: "物料分类",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "oldEquipCode",
    display: true,
    fieldLabel: "原设备号",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "equipCode",
    display: true,
    fieldLabel: "新设备号",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "fixedAssets",
    display: true,
    fieldLabel: "固资/存货",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "isChangeSimStr",
    display: true,
    fieldLabel: "是否需要更换SIM卡",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "cardOprTypeStr",
    display: true,
    fieldLabel: "卡操作",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "oldSimNoStr",
    display: true,
    fieldLabel: "旧SIM卡",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "newSimNoStr",
    display: true,
    fieldLabel: "新SIM卡",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "serverOpenTimeList",
    display: true,
    fieldLabel: "首次服务开启时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastRepairTime",
    display: true,
    fieldLabel: "上一次维修时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastQuesReason",
    display: true,
    fieldLabel: "上一次故障现象",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastRepairSolution",
    display: true,
    fieldLabel: "上一次维修处理措施",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastRepairEngineer",
    display: true,
    fieldLabel: "上一次维修工程师",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "facilitatorName",
    display: true,
    fieldLabel: "服务商名称",
    width: 110,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "orderTaskSourceStr",
    display: true,
    fieldLabel: "工单来源",
    width: 90,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "abnormalReason",
    display: true,
    fieldLabel: "异常原因",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workSubmitUser",
    display: true,
    fieldLabel: "工单提交人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workSubmitTime",
    display: true,
    fieldLabel: "工单提交时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workFinishTime",
    display: true,
    fieldLabel: "工单完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "createdTime",
    display: true,
    fieldLabel: "质检单创建时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "detectionFinishTimeList",
    display: true,
    fieldLabel: "车辆检测完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionFailReason",
    display: true,
    fieldLabel: "质检不通过原因",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "noPassInspectionItem",
    display: true,
    fieldLabel: "不通过检测项",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "quesType",
    display: true,
    fieldLabel: "问题类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "quesName",
    display: true,
    fieldLabel: "问题描述",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "engineerMainBody",
    display: true,
    fieldLabel: "主体（工程师）",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "quesReason",
    display: true,
    fieldLabel: "故障现象",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "faultClass",
    display: true,
    fieldLabel: "故障归类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "solution",
    display: true,
    fieldLabel: "处理措施",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "faultReason",
    display: true,
    fieldLabel: "故障原因",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "responsibleEntity",
    display: true,
    fieldLabel: "责任主体",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    fieldName: "customResponsibility",
    display: true,
    fieldLabel: "客责初判",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "engineerResponsibility",
    display: true,
    fieldLabel: "客责确认",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "startInspectionTime",
    display: true,
    fieldLabel: "开始质检时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionUpdateTime",
    display: true,
    fieldLabel: "质检记录更新时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastRejectTime",
    display: true,
    fieldLabel: "最近一次驳回时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "rejectSubmitTime",
    display: true,
    fieldLabel: "最近一次驳回提交时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "taskInspectionStatusValue",
    display: true,
    fieldLabel: "质检状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "responsibilityNameStr",
    display: true,
    fieldLabel: "故障责任归属",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "mainBodyNameStr",
    display: true,
    fieldLabel: "主体（质检员）",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "failPointNameStr",
    display: true,
    fieldLabel: "故障点",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "isProcessCorrectionName",
    display: true,
    fieldLabel: "是否工艺纠正",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "correctiveActionName",
    display: true,
    fieldLabel: "异常纠正类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "processCorrection",
    display: true,
    fieldLabel: "纠正内容",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "rejectReason",
    display: true,
    fieldLabel: "驳回原因",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "rejectUser",
    display: true,
    fieldLabel: "驳回提交人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastPassTime",
    display: true,
    fieldLabel: "最后一次复检通过时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "recheckPassReason",
    display: true,
    fieldLabel: "复核通过原因",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionDeduction",
    display: true,
    fieldLabel: "质检扣费(元)",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "deductionReason",
    display: true,
    fieldLabel: "扣费原因",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "zeroSettleName",
    display: true,
    fieldLabel: "是否0结算",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionOperator",
    display: true,
    fieldLabel: "质检人",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workMonth",
    display: true,
    fieldLabel: "实施月份",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "regionalName",
    display: true,
    fieldLabel: "任务所属大区",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "areaName",
    display: true,
    fieldLabel: "任务所属片区",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "provinceName",
    display: true,
    fieldLabel: "作业省份",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "countyName",
    display: true,
    fieldLabel: "作业区/县",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "cityName",
    display: true,
    fieldLabel: "作业城市",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "insAddress",
    display: true,
    fieldLabel: "详细地址",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
export const installColumnData = [
  {
    fieldName: "itemId",
    display: true,
    fieldLabel: "工单号",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "taskSecondClassStr",
    display: true,
    fieldLabel: "工单类型",
    width: 110,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "isNeedVisitName",
    display: true,
    fieldLabel: "是否上门",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "corpName",
    display: true,
    fieldLabel: "客户名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "nowOperationOrgName",
    display: true,
    fieldLabel: "现运营组织",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "engineerName",
    display: true,
    fieldLabel: "工程师",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionTagName",
    display: true,
    fieldLabel: "质检标签",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "sceneName",
    display: true,
    fieldLabel: "场景名称",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "sourceOrganizationName",
    display: true,
    fieldLabel: "上线平台",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "materialName",
    display: true,
    fieldLabel: "物料名称",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "classifyName",
    display: true,
    fieldLabel: "物料分类",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "oldEquipCode",
    display: true,
    fieldLabel: "原设备号",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "equipCode",
    display: true,
    fieldLabel: "新设备号",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "fixedAssets",
    display: true,
    fieldLabel: "固资/存货",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "isChangeSimStr",
    display: true,
    fieldLabel: "是否需要更换SIM卡",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "cardOprTypeStr",
    display: true,
    fieldLabel: "卡操作",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "oldSimNoStr",
    display: true,
    fieldLabel: "旧SIM卡",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "newSimNoStr",
    display: true,
    fieldLabel: "新SIM卡",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "serverOpenTimeList",
    display: true,
    fieldLabel: "首次服务开启时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    fieldName: "facilitatorName",
    display: true,
    fieldLabel: "服务商名称",
    width: 110,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "orderTaskSourceStr",
    display: true,
    fieldLabel: "工单来源",
    width: 90,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "abnormalReason",
    display: true,
    fieldLabel: "异常原因",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workSubmitUser",
    display: true,
    fieldLabel: "工单提交人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workSubmitTime",
    display: true,
    fieldLabel: "工单提交时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workFinishTime",
    display: true,
    fieldLabel: "工单完成时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "createdTime",
    display: true,
    fieldLabel: "质检单创建时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "detectionFinishTimeList",
    display: true,
    fieldLabel: "车辆检测完成时间",
    width: 200,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionFailReason",
    display: true,
    fieldLabel: "质检不通过原因",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "noPassInspectionItem",
    display: true,
    fieldLabel: "不通过检测项",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "startInspectionTime",
    display: true,
    fieldLabel: "开始质检时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionUpdateTime",
    display: true,
    fieldLabel: "质检记录更新时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastRejectTime",
    display: true,
    fieldLabel: "最近一次驳回时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "rejectSubmitTime",
    display: true,
    fieldLabel: "最近一次驳回提交时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "taskInspectionStatusValue",
    display: true,
    fieldLabel: "质检状态",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },

  {
    fieldName: "isProcessCorrectionName",
    display: true,
    fieldLabel: "是否工艺纠正",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "correctiveActionName",
    display: true,
    fieldLabel: "异常纠正类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "processCorrection",
    display: true,
    fieldLabel: "纠正内容",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "rejectReason",
    display: true,
    fieldLabel: "驳回原因",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "rejectUser",
    display: true,
    fieldLabel: "驳回提交人",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "lastPassTime",
    display: true,
    fieldLabel: "最后一次复检通过时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "recheckPassReason",
    display: true,
    fieldLabel: "复核通过原因",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionDeduction",
    display: true,
    fieldLabel: "质检扣费(元)",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "deductionReason",
    display: true,
    fieldLabel: "扣费原因",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "zeroSettleName",
    display: true,
    fieldLabel: "是否0结算",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionOperator",
    display: true,
    fieldLabel: "质检人",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "workMonth",
    display: true,
    fieldLabel: "实施月份",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "regionalName",
    display: true,
    fieldLabel: "任务所属大区",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "areaName",
    display: true,
    fieldLabel: "任务所属片区",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "provinceName",
    display: true,
    fieldLabel: "作业省份",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "countyName",
    display: true,
    fieldLabel: "作业区/县",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "cityName",
    display: true,
    fieldLabel: "作业城市",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "insAddress",
    display: true,
    fieldLabel: "详细地址",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
